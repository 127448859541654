import { gsap } from "gsap";

const workTitle = document.querySelector(".work-title");
const workImage = document.querySelector(".work-image");
const workImages = document.querySelector("#work-images");
const homepageSmall = document.querySelector(".homepage-small");
const homepageLarge = document.querySelector(".homepage-large");
const serviceTitle = document.querySelector("#service-title");
const contactContent = document.querySelector("#contact-content");

const fadeInUp = target => {
  gsap.from(target, {
    opacity: 0,
    y: 16
  });
};

// Work titles.
if (workTitle) {
  fadeInUp(workTitle);
}

// Work images.
if (workImage) {
  gsap.from(".work-image", {
    y: 16,
    opacity: 0,
    stagger: 0.25
  });
}

// Homepage title.
if (homepageSmall && homepageLarge) {
  const timeline = gsap.timeline();

  timeline.from(homepageLarge, {
    opacity: 0,
    y: 16
  });

  timeline.from(homepageSmall, {
    opacity: 0,
    x: -16
  });
}

// Service title.
if (serviceTitle) {
  fadeInUp(serviceTitle);
}

// Contact content.
if (contactContent) {
  fadeInUp(contactContent);
}
