const header = document.querySelector("#page-header");
const logo = document.querySelector("#logo");
const logoSmall = document.querySelector("#logo-small");
const mainNav = document.querySelector("#main-nav");

let last_known_scroll_position = 0;
let ticking = false;

window.addEventListener("scroll", () => {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
        if (last_known_scroll_position > 0) {
          if (!header.classList.contains("scrolled")) {
            // Adjust header height.
            header.classList.remove("md:h-625");
            header.classList.add("scrolled");

            // Swap logos.
            logo.classList.add("hidden");
            logoSmall.classList.remove("hidden");

            // Adjust main nav.
            mainNav.classList.remove("md:top-625");
          }
        } else {
          // Adjust header height.
          header.classList.remove("scrolled");
          header.classList.add("md:h-625");

          // Adjust logo height.
          logo.classList.remove("hidden");
          logoSmall.classList.add("hidden");

          // Adjust main nav.
          mainNav.classList.add("md:top-625");
        }

        ticking = false;
    });

    ticking = true;
  }
});
