import { gsap } from "gsap";

const mobileNavToggle = document.querySelector("#burger-king");
const mobileNav = document.querySelector("#main-nav");

mobileNavToggle.addEventListener("click", () => {
  mobileNav.classList.toggle("hidden");

  mobileNavToggle.classList.toggle("open");
  mobileNavToggle.querySelector(".burger").classList.toggle("opacity-0");
  mobileNavToggle.querySelector(".top").classList.toggle("rotate-45");
  mobileNavToggle.querySelector(".bottom").classList.toggle("-rotate-45");

  gsap.from(".nav-item", {
    opacity: 0
  });
});
